<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card no-body class="card-statistics">
          <router-link to="/units">
            <b-card-header>
              <b-card-title>Unit Roda 4</b-card-title>
            </b-card-header>
            <b-card-body class="px-3 py-1">
              <b-row class="mb-3">
                <b-col xl="4" sm="6">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-success">
                        <feather-icon size="24" icon="CheckCircleIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ cars.available }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Tersedia
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col xl="4" sm="6">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-warning">
                        <feather-icon size="24" icon="FileTextIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">{{ cars.rented }}</h4>
                      <b-card-text class="font-small-3 mb-0">
                        Tersewa
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col xl="4" sm="6">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-primary">
                        <feather-icon size="24" icon="LayersIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">{{ cars.total }}</h4>
                      <b-card-text class="font-small-3 mb-0">
                        Total
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col xl="4" sm="6">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-dark">
                        <feather-icon size="24" icon="LinkIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ cars.onprocess }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Dalam Proses
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col xl="4" sm="6">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-primary">
                        <feather-icon size="24" icon="SettingsIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">{{ cars.maintenance }}</h4>
                      <b-card-text class="font-small-3 mb-0">
                        Dalam Perbaikan
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </router-link>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body class="card-statistics">
          <router-link to="/units">
            <b-card-header>
              <b-card-title>Unit Roda 2</b-card-title>
            </b-card-header>
            <b-card-body class="px-3 py-1">
              <b-row class="mb-3">
                <b-col xl="4" sm="6">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-success">
                        <feather-icon size="24" icon="CheckCircleIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ motorcycles.available }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Tersedia
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col xl="4" sm="6">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-warning">
                        <feather-icon size="24" icon="FileTextIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ motorcycles.rented }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Tersewa
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col xl="4" sm="6">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-primary">
                        <feather-icon size="24" icon="LayersIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ motorcycles.total }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Total
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col xl="4" sm="6">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-dark">
                        <feather-icon size="24" icon="LinkIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ motorcycles.onprocess }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Dalam Proses
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col xl="4" sm="6">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-primary">
                        <feather-icon size="24" icon="SettingsIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ motorcycles.maintenance }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Dalam Perbaikan
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </router-link>
        </b-card>
      </b-col>
    </b-row>
    <b-card no-body class="card-statistics">
      <b-card-header>
        <b-card-title>Permintaan</b-card-title>
      </b-card-header>
      <b-card-body class="px-3 py-1">
        <b-row class="mb-3">
          <b-col xl="4" sm="6">
            <router-link to="/emergencies">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-danger">
                    <feather-icon size="24" icon="AlertCircleIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">{{ emergency }}</h4>
                  <b-card-text class="font-small-3 mb-0"> Darurat </b-card-text>
                </b-media-body>
              </b-media>
            </router-link>
          </b-col>
          <b-col xl="4" sm="6">
            <router-link to="/request/towings">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-warning">
                    <feather-icon size="24" icon="AlertTriangleIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">{{ towing }}</h4>
                  <b-card-text class="font-small-3 mb-0"> Towing </b-card-text>
                </b-media-body>
              </b-media>
            </router-link>
          </b-col>
          <b-col xl="4" sm="6">
            <router-link to="/sales/quotations">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-success">
                    <feather-icon size="24" icon="UserIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">{{ quotation }}</h4>
                  <b-card-text class="font-small-3 mb-0">
                    Penawaran Harga
                  </b-card-text>
                </b-media-body>
              </b-media>
            </router-link>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col xl="4" sm="6">
            <router-link to="/request/part-replacements">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-primary">
                    <feather-icon size="24" icon="FileTextIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">{{ partReplacement }}</h4>
                  <b-card-text class="font-small-3 mb-0">
                    Penggantian Ban / Aki
                  </b-card-text>
                </b-media-body>
              </b-media>
            </router-link>
          </b-col>
          <!--<b-col xl="4" sm="6">
            <router-link to="/request/unit-replacements">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-primary">
                    <feather-icon size="24" icon="FileTextIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">{{ unitReplacement }}</h4>
                  <b-card-text class="font-small-3 mb-0">
                    Penggantian Unit
                  </b-card-text>
                </b-media-body>
              </b-media>
            </router-link>
          </b-col>-->
          <b-col xl="4" sm="6">
            <router-link to="/request/services">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-primary">
                    <feather-icon size="24" icon="FileTextIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">{{ insurance }}</h4>
                  <b-card-text class="font-small-3 mb-0"> Service </b-card-text>
                </b-media-body>
              </b-media>
            </router-link>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- <b-row>
      <b-col cols="12" md="4" lg="4">
        <router-link to="/units">
          <statistic-card-horizontal
            id="dashboard-statistic"
            icon="TruckIcon"
            :statistic="cars"
            statistic-title="Unit Roda 4"
          />
        </router-link>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <router-link to="/units">
          <statistic-card-horizontal
            id="dashboard-statistic"
            icon="TruckIcon"
            :statistic="motorcycles"
            statistic-title="Unit Roda 2"
          />
        </router-link>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <router-link to="/customers">
          <statistic-card-horizontal
            id="dashboard-statistic"
            icon="UsersIcon"
            :statistic="customers"
            :statistic-title="'Pelanggan'"
          />
        </router-link>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <router-link to="/contracts">
          <statistic-card-horizontal
            id="dashboard-statistic"
            icon="FileTextIcon"
            :statistic="contracts"
            :statistic-title="'Kontrak Aktif'"
          />
        </router-link>
      </b-col>
    </b-row> -->
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BContainer,
} from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import moment from "moment";

export default {
  components: {
    StatisticCardHorizontal,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BContainer,
  },
  data() {
    return {
      cars: 0,
      motorcycles: 0,
      contracts: 0,
      customers: 0,
      emergency: 0,
      towing: 0,
      partReplacement: 0,
      unitReplacement: 0,
      insurance: 0,
      quotation: 0,
      permissions: [],
    };
  },
  created() {
    this.getStatistic();
  },
  methods: {
    moment: function () {
      return moment();
    },
    getStatistic() {
      this.$http.get("statistics").then((response) => {
        // console.log(response.data.data)
        let $data = response.data.data;
        this.cars = $data.unit.car;
        this.motorcycles = $data.unit.motorcycle;
        this.customers = $data.customer;
        this.contracts = $data.contract;
        this.emergency = $data.emergency.waiting;
        this.towing = $data.towing.waiting;
        this.partReplacement = $data.part_replacement.waiting;
        this.unitReplacement = $data.unit_replacement.waiting;
        this.insurance = $data.insurance.waiting;
        this.quotation = $data.quotation.waiting;
      });
    },
  },
};
</script>

<style>
div.card#dashboard-statistic {
  border-left: 5px solid #2296d4;
}

div.card#dashboard-statistic:hover {
  border-left: 10px solid #2296d4;
}
</style>
